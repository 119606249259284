.smind-app {
    --space-0: 0px;
    --space-025: 1px;
    --space-050: 2px;
    --space-100: 4px;
    --space-150: 6px;
    --space-200: 8px;
    --space-250: 10px;
    --space-300: 12px;
    --space-400: 16px;
    --space-450: 18px;
    --space-500: 20px;
    --space-600: 24px;
    --space-650: 26px;
    --space-700: 28px;
    --space-750: 30px;
    --space-800: 32px;
    --color-border: #E3E3E3;
    --color-border-secondary: #EBEBEB;
    --color-surface-secondary: #F7F7F7;
    --color-surface-secondary-hover: #F1F1F1;
    --color-bg-surface-selected: #F1F1F1;
    --color-surface-secondary-selected: #EBEBEB;
    --color-bg-fill-info-secondary: #E0F0FF;
    --color-bg-fill-success-secondary: #CDFEE1;
    --color-bg-surface-hover: #F7F7F7;
    --color-bg-fill-brand: #303030;
    --color-text: #303030;
    --color-text-success: #0C5132;
    --color-text-info: #00527C;
    --color-text-secondary: #616161;
    --border-radius-200: 8px;
    --border-radius-150: 6px;
}