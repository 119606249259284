.section-item {

    &:hover{
        .thumbnail {
            .lazy-load-image-background {
                opacity: 0.5;
            }
            &__search {
                display: block;
            }
        }
    }

    .Polaris-Box {
        padding: var(--space-300);
        .add-coupon-code{
            color: #005BD3;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .labels {
        ._price-origin {
            color: #616161;
            text-decoration: line-through;
        }
    }

    .thumbnail {
        aspect-ratio: 3/2;

        &__search {
            display: none;
            bottom: 0;
            right: 0;
            .Polaris-Button {
                padding: var(--space-100);
            }
        }

        .Polaris-SkeletonThumbnail {
            width: 100%;
            height: 90%;
            display: none;
        }
        .lazy-load-image-background {
           line-height: 0;
           border-radius: var(--space-150);
            position: relative;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            overflow: hidden;
            border: 1px solid var(--color-border-secondary); 
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .info {
        border-top: 1px solid var(--color-border-secondary);
        margin-left: calc(var(--space-300) * -1);
        margin-right: calc(var(--space-300) * -1);
        .name  {
            .Polaris-SkeletonBodyText {
                height: 7px;
            }
            .Polaris-SkeletonBodyText + .Polaris-SkeletonBodyText {
                margin-top: 6px;
            }
        }
    }

    .actions {
        .more-actions {
            flex: 1;

            div {
                &:first-child {
                    width: 100%;
                    button {
                        width: 100%;
                    }
                }
            }
        }

        button {
            &:last-child{
                padding: var(--space-100);
            }            

            &:first-child {
                flex: 1;
            }
        }
    }
}

.animation {
    opacity: 0;
    animation: fadeIn 0.25s ease-in forwards;
}

.iframe-free-trial {
    margin-bottom: 8px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    70% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}