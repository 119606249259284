* {
    box-sizing: border-box;
}

.container-lg {
    width: 1200px;
    max-width: 100%;
    // padding-left: var(--space-400);
    // padding-right: var(--space-400);
    margin-left: auto;
    margin-right: auto;
}

.container-md {
    width: 1000px;
    max-width: 100%;
    // padding-left: var(--space-600);
    // padding-right: var(--space-600);
    margin-left: auto;
    margin-right: auto;
}

.container-sm {
    width: 668px;
    max-width: 100%;
    // padding-left: var(--space-600);
    // padding-right: var(--space-600);
    margin-left: auto;
    margin-right: auto;
}

body {
    overflow: clip scroll;
}

.route-wrapper {
    // min-height: calc(100vh + 10px);
}

.line-height-0 {
    line-height: 0;
}

[data-lock-scrolling][data-lock-scrolling-hidden] {
    overflow-y: scroll;
}
$properties: (
    "0": var(--space-0),
    "025": var(--space-025),
    "050": var(--space-050),
    "100": var(--space-100),
    "150": var(--space-150),
    "200": var(--space-200),
    "250": var(--space-250),
    "300": var(--space-300),
    "400": var(--space-400),
    "450": var(--space-450),
    "500": var(--space-500),
    "600": var(--space-600),
    "650": var(--space-650),
    "700": var(--space-700),
    "750": var(--space-750),
    "800": var(--space-800),
    "900": var(--space-900)
);

@each $name, $size in $properties {
    .m-#{$name} {
        margin: $size;
    }
    .mx-#{$name} {
        margin-left: $size;
        margin-right: $size;
    }
    .ml-#{$name} {
        margin-left: $size;
    }
    .mr-#{$name} {
        margin-right: $size;
    }
    .my-#{$name} {
        margin-top: $size;
        margin-bottom: $size;
    }
    .mt-#{$name} {
        margin-top: $size;
    }
    .mb-#{$name} {
        margin-bottom: $size;
    }
    
    .p-#{$name} {
        padding: $size;
    }
    .px-#{$name} {
        padding-left: $size;
        padding-right: $size;
    }
    .pl-#{$name} {
        padding-left: $size;
    }
    .pr-#{$name} {
        padding-right: $size;
    }
    .py-#{$name} {
        padding-top: $size;
        padding-bottom: $size;
    }
    .pt-#{$name} {
        padding-top: $size;
    }
    .pb-#{$name} {
        padding-bottom: $size;
    }

    .gap-#{$name} {
        gap: $size;
    }

    .column-gap-#{$name} {
        column-gap: $size;
    }

    .row-gap-#{$name} {
        row-gap: $size;
    }
}

$heading-sizes: (
    "sm": 13px
);

@each $name, $size in $heading-sizes {
    .heading-#{$name} {
        font-size: $size;
    }
}

$body-sizes: (
    "sm": 12px,
    "md": 13px,
);

@each $name, $size in $body-sizes {
    .body-#{$name} {
        font-size: $size;
    }
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.px-10 {
    padding-left: 10px;
    padding-right: 10px;
}

//Grid
.d-none {
    display: none;
}

.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.d-inline-flex {
    display: inline-flex;
}

.align-items-center {
    align-items: center;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-end {
    align-items: end;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-content-end {
    justify-content: end;
}


.border-radius-200 {
    border-radius: var(--border-radius-200);
}

.border-radius-150 {
    border-radius: var(--border-radius-150);
}

.border-1-white {
    border: 1px solid #fff;
}

.p-relative {
    position: relative;
}

.w-70 {
    width: 70%;
}

.w-100 {
    width: 100%;
}

.h-auto {
    height: auto;
}

.mw-100 {
    max-width: 100%;
}

.minw-100 {
    min-width: 100%;
}


.mh-100 {
    max-height: 100%;
}

.object-fit-cover {
    object-fit: cover;
}

.background-white {
    background: #fff;
}
.background-info-secondary {
    background: var(--color-bg-fill-info-secondary)
}

.color-text-secondary {
    color: var(--color-text-secondary);
}

.color-info {
    color: var(--p-color-text-info);
} 

.background-success-secondary {
    background: var(--color-bg-fill-success-secondary)
}

.color-success {
    color: var(--p-color-text-success);
} 

.pb-0 {
    padding-bottom: 0;
}

.hidden {
    display: none;
}

.min-height100vh {
    height: calc(100vh + 50px);
}

.fw-650 {
    font-weight: 650;
}

.fw-450 {
    font-weight: 450;
}

.fw-550 {
    font-weight: 550;
}

.flex-1 {
    flex: 1;
}

.col-4 {
    width: 33.33%;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-column {
    flex-direction: column;
}

.row {
    margin-left: calc(var(--space-200) * -1);
    margin-right: calc(var(--space-200) * -1);
}

div[class*="col-"] {
    padding: 0 var(--space-200);
}

.p-relative {
    position: relative;
}

.p-absolute {
    position: absolute;
}

.p-fixed {
    position: fixed;
}

.text-align-center {
    text-align: center;
}



.iframe-responsive {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.bulk-actions {
    z-index: 999;
    bottom: -50px;
    opacity: 0;
    transition: all 0.1s;
    .Polaris-ShadowBevel {
       box-shadow: rgba(26, 26, 26, 0.2) 0px 4px 6px -2px;
    }
    button {
        background: #e3e3e3;
        box-shadow: none;
        &:hover {
            background: #f7f7f7;
        }
    }

    &.show {
        opacity: 1;
        bottom: 40px;
    }
}

.aspect-ratio-169 {
    aspect-ratio: 16/9;
}

@media (min-width: 30.625em) {
    .container-lg {
        padding-left: var(--space-600);
        padding-right: var(--space-600);
    }
}

@media screen and (max-width: 767px) {
    body {
        overflow: clip auto;
    }
}

.loading {
    line-height: 0;
    svg {
        width: 20px !important;
        height: 20px !important;
    }
}