.page-admin-tools {
    background: #f1f1f1;
    min-height: 100vh;

    .Polaris-TopBar__LogoLink {
        img {
            width: 60px;
        }
    }

    .Polaris-Page {
        max-width: unset;
    }

    .update-tool {
        .list-sections {
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: 20px;

            .item {
                width: calc(100%/3 - 14px);
                background: #eee;
                padding: 3px;
                border-radius: 5px;

                .thumbnail {
                    padding-bottom: 52%;
                    position: relative;

                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                    }
                }

                .info {
                    padding: 10px;

                    h3 {
                        font-weight: 550;
                        font-size: 16px;

                        span {
                            background: orange;
                            font-size: 13px;
                            display: inline-block;
                            padding: 2px 10px 4px 10px;
                            line-height: 11px;
                            border-radius: 3px;
                            color: #fff;
                            text-align: center;
                        }
                    }

                    .action {
                        margin-top: 12px;
                    }
                }
            }
        }
    }

    ._sidebar {
        background: #ebebeb;
        width: 240px;
        min-width: 240px;

        .item {
            border-radius: 6px;
            padding: 10px;

            &.active {
                background: #fff;
            }

            &:hover:not(.active) {
                background: rgba(255, 255, 255, 0.4);
            }

            .Polaris-Icon {
                margin: 0;
            }
        }
    }

    ._content {
        flex: 1;
        width: calc(100% - 256px);

        .container-lg {
            padding: 0;
        }
    }

    .css-records {
        textarea {
            max-height: 350px;
        }
    }
}

.admin-merchant-logs-page {
    ._item {
        margin-bottom: 25px;

        ._colleft {
            width: 30%;
        }

        ._colright {
            width: 70%;

            ._tab {
                ._tab-content {
                    margin-top: 8px;

                    .logs-records {
                        max-height: 450px;
                        overflow-y: auto;
                    }

                    ._tab-content-list {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        max-height: 560px;
                        overflow-y: auto;
                        gap: 20px;

                        ._list-item {
                            width: 48%;
                            border: 1px solid #dfdfdf;
                            border-radius: 10px;
                            overflow: hidden;

                            .item-name {
                                padding: 10px;
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

.item-thumbnail {
    aspect-ratio: 3/2;
    position: relative;
    display: flex;
    align-items: center;
    background: #eee;

    img {
        width: 100%;
        height: auto;
        object-fit: cover;

    }

    &--template {
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
        }
    }
}

.item-name {
    &--template {
        text-align: left !important;
    }
}

.admin-coupons-page {
    ._item {
        padding: 15px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 4px gray;
        margin-bottom: 25px;



    }

    .form-coupon {
        border: 1px solid grey;
        padding: 5px 10px;
        border-radius: 10px;
        background: rgb(240, 240, 240);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        grid-gap: 20px;
        margin-bottom: 20px;
        text-align: center;

        .w-40 {
            width: calc(40% - (2/3*20px))
        }

        .w-15 {
            width: calc(15% - (5/6*20px))
        }

        .w-12 {
            width: calc(15.5% - (5/6*20px))
        }

        .w-10 {
            width: calc(10% - (5/6*20px))
        }

        .w-7-5 {
            text-align: center;
            width: calc(8.5% - (5/6*20px))
        }

        .w-5 {
            width: calc(5% - (5/6*20px))
        }

        .w-30 {
            width: calc(30% - (2/3*20px))
        }

        .w-25 {
            width: calc(25% - (5/6*20px))
        }

        .w-20 {
            width: calc(20% - (5/6*20px))
        }

        button {
            width: 100%
        }

        .chb {
            display: block;
            text-align: left;
            width: calc(10% - (5/6*20px))
        }

        .delete {
            // margin: auto 0px auto auto;
            margin: auto;
        }

        .use-apply,
        .discount-code-percent {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 10px 0px;

            &.use-apply {
                div {
                    width: 100%;
                }
            }
        }

    }

    .action {
        // margin: auto 0px auto auto;
        width: calc(30% - 4/6 *20px);
        position: fixed;
        bottom: 0px;
        padding: 10px 30px;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 15px;

        button {
            width: 100%
        }
    }

    .add {
        // margin: auto 0px auto auto;
        width: calc(15% - (5/6*20px));
        display: flex;
        margin: auto 0px auto auto;
        flex-wrap: wrap;
        grid-gap: 15px;

        button {
            width: 100%
        }
    }
}

.all-merchants {
    table {
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        border-collapse: collapse;

        tr {
            th {
                text-align: left;
                font-size: 14px;
                font-weight: 600;
                background: #e3e3e3;
                padding: 10px;
            }

            td {
                padding: 20px 10px;
                background: #eeeeee7d;
                border-top: 1px solid #dfdfdf;

                a {
                    color: #0058ffb5;
                }
            }
        }
    }
}

@media screen and (max-width: 1150px) {
    .page-admin-tools {
        flex-wrap: wrap;
        min-height: auto;

        ._sidebar {
            width: 100%;
            height: fit-content;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .mb-400 {
                display: none;
            }
        }
    }
}

.page-login {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    ._inner {
        width: 400px;
        padding: 15px 20px;
    }
}

//Section button
.pricing-section-button {
    position: fixed;
    bottom: 0;
    left: 0;
    left: 324px;
    width: 400px;
    z-index: 9999;
    background: #f7f7f7;
    padding: 10px;
    border-top: 1px solid #ccc;
}

.pricing-template-button {
    position: fixed;
    bottom: 0;
    left: 0;
    left: 324px;
    width: 600px;
    z-index: 9999;
    background: #f7f7f7;
    padding: 10px;
    border-top: 1px solid #ccc;
}

.text-link {
    color: #006fbb;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
.text-link-dark {
    color: #333;
}